<template>
  <div>
    <h1 class="title is-space-between-flex">
      {{ $t('professionalaccess.req-requests.title') }}
      <b-button
        tag="router-link"
        to="/req/form"
        type="is-primary"
        class="has-text-weight-normal"
      >
        {{ $t('professionalaccess.req-requests.new') }}
      </b-button>
    </h1>
    <b-table
      :data="data"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        v-slot="props"
        field="neq"
        :label="$t('professionalaccess.req-requests.column.neq')"
      >
        {{ props.row.form.neq.neq }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="name"
        :label="$t('professionalaccess.req-requests.column.name')"
      >
        {{ props.row.form.neq.nomEntreprise }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        field="formState"
        :label="$t('professionalaccess.req-requests.column.formState')"
      >
        {{ $t(`formState.${props.row.formState}`) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        field="creationDate"
        :label="$t('professionalaccess.req-requests.column.creationDate')"
      >
        {{ formatDate(props.row.creationDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        field="lastUpdatedDate"
        :label="$t('professionalaccess.req-requests.column.lastUpdatedDate')"
      >
        {{ formatDate(props.row.lastUpdatedDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        width="100px"
      >
        <div class="has-text-center">
          <b-button
            type="is-secondary"
            size="is-small"
            :icon-right="props.row.formState === 'DRAFT' ? 'edit' : 'eye'"
            @click="goToRequest(props.row)"
          />
          <b-button
            v-if="props.row.formState === 'DRAFT'"
            class="ml-2"
            type="is-secondary"
            size="is-small"
            icon-right="trash"
            @click="deleteDraft(props.row.id)"
          />
        </div>
      </b-table-column>
      <template slot="empty">
        {{ $t('professionalaccess.req-requests.noData') }}
      </template>
    </b-table>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data () {
    return {
      data: [],
      total: 0,
      loading: false,
      sortField: 'lastUpdatedDate',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20
    };
  },
  mounted () {
    this.loadAsyncData();
  },
  methods: {
    formatDate (dateString) {
      return this.$moment(dateString).format('DD-MM-YYYY HH:mm:ss');
    },
    loadAsyncData () {
      this.loading = true;

      api
        .getReqForms(this.page - 1, this.perPage, this.sortField, this.sortOrder)
        .then((resp) => {
          this.data = resp.data.content
            .map(req => {
              return {
                ...req,
                form: JSON.parse(req.form)
              };
            });
          this.total = resp.data.totalElements;
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPageChange (page) {
      this.page = page;
      this.loadAsyncData();
    },
    onSort (field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    goToRequest (row) {
      if (row.formState === 'DRAFT') {
        this.$router.push({ name: 'req-form', query: { draftId: row.id } });
      } else {
        this.$router.push({ name: 'req-details', params: { id: row.id } });
      }
    },
    deleteDraft (draftId) {
      this.$buefy.dialog.confirm({
        message: this.$t('professionalaccess.req-requests.delete.message'),
        cancelText: this.$t('professionalaccess.req-requests.delete.cancel'),
        confirmText: this.$t('professionalaccess.req-requests.delete.confirm'),
        focusOn: 'cancel',
        onConfirm: () => {
          api.deleteDraft(draftId)
            .then(() => this.loadAsyncData());
        }
      });
    }
  }
};
</script>
