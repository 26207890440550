<template>
  <div>
    <h1 class="title is-space-between-flex">
      {{ $t('professionalaccess.incorporation-requests.title') }}
      <b-dropdown
        @change="goToForm"
        aria-role="list"
      >
        <template #trigger="{ active }">
          <b-button
            :label="$t('professionalaccess.incorporation-requests.newRequest')"
            type="is-primary"
            :icon-right="active ? 'chevron-up' : 'chevron-down'"
          />
        </template>
        <b-dropdown-item
          value="QC_NUM"
          aria-role="listitem"
        >
          {{ $t('formType.QC_NUM') }}
        </b-dropdown-item>
        <b-dropdown-item
          value="QC_NAME"
          aria-role="listitem"
        >
          {{ $t('formType.QC_NAME') }}
        </b-dropdown-item>
        <b-dropdown-item
          value="CA_NUM"
          aria-role="listitem"
        >
          {{ $t('formType.CA_NUM') }}
        </b-dropdown-item>
        <b-dropdown-item
          value="CA_NAME"
          aria-role="listitem"
        >
          {{ $t('formType.CA_NAME') }}
        </b-dropdown-item>
      </b-dropdown>
    </h1>
    <b-table
      :data="data"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      hoverable
      @click="goToRequest"
    >
      <b-table-column
        v-slot="props"
        field="id"
        :label="$t('professionalaccess.incorporation-requests.column.id')"
      >
        <span class="is-family-monospace">{{ formatId(props.row.id) }}</span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        field="formType"
        :label="$t('professionalaccess.incorporation-requests.column.formType')"
      >
        {{ $t(`formType.${props.row.formType}`) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        field="formState"
        :label="$t('professionalaccess.incorporation-requests.column.formState')"
      >
        {{ $t(`formState.${props.row.formState}`) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        field="creationDate"
        :label="$t('professionalaccess.incorporation-requests.column.creationDate')"
      >
        {{ formatDate(props.row.creationDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        field="lastUpdatedDate"
        :label="$t('professionalaccess.incorporation-requests.column.lastUpdatedDate')"
      >
        {{ formatDate(props.row.lastUpdatedDate) }}
      </b-table-column>
      <template slot="empty">
        {{ $t('professionalaccess.incorporation-requests.noData') }}
      </template>
    </b-table>
  </div>
</template>

<script>
import api from '@/api';
import { mapActions } from 'vuex';

export default {
  data () {
    return {
      data: [],
      total: 0,
      loading: false,
      sortField: 'lastUpdatedDate',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20
    };
  },
  mounted () {
    this.loadAsyncData();
  },
  methods: {
    ...mapActions('form', [
      'startForm'
    ]),
    formatDate (dateString) {
      return this.$moment(dateString).format('DD-MM-YYYY HH:mm:ss');
    },
    loadAsyncData () {
      this.loading = true;

      api
        .getIncorporationForms(this.page - 1, this.perPage, this.sortField, this.sortOrder)
        .then((resp) => {
          this.data = resp.data.content;
          this.total = resp.data.totalElements;
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPageChange (page) {
      this.page = page;
      this.loadAsyncData();
    },
    onSort (field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    formatId (id) {
      return id.substr(0, 8).toUpperCase()
    },
    goToRequest (row) {
      this.$router.push({ name: 'incorporation-details', params: { id: row.id } });
    },
    goToForm (value) {
      this.startForm(value);
      this.$router.push({ name: 'incorporation-form' });
    }
  }
};
</script>
