<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-3">
          <b-menu>
            <b-menu-list label="Menu">
              <b-menu-item
                v-if="hasReqAccess"
                icon="clipboard-list"
                :label="$t('professionalaccess.req-requests.title')"
                @click="goTo(views.REQ)"
                :active="activeView === views.REQ.key"
              ></b-menu-item>
              <b-menu-item
                v-if="hasIncorporationAccess"
                icon="clipboard-list"
                :label="$t('professionalaccess.incorporation-requests.title')"
                @click="goTo(views.INCORPORATION)"
                :active="activeView === views.INCORPORATION.key"
              ></b-menu-item>
              <b-menu-item
                icon="user"
                :label="$t('professionalaccess.profile.title')"
                @click="goTo(views.PROFILE)"
                :active="activeView === views.PROFILE.key"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Actions">
              <b-menu-item
                icon="sign-out-alt"
                :label="$t('logout')"
                @click="logout()"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
        <div class="column is-9">
          <req-requests v-if="activeView === views.REQ.key && hasReqAccess"></req-requests>
          <incorporation-requests v-if="activeView === views.INCORPORATION.key && hasIncorporationAccess"></incorporation-requests>
          <professional-access-profile v-if="activeView === views.PROFILE.key"></professional-access-profile>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/mixins/auth';
import ProfessionalAccessProfile from '@/components/professionalaccess/PAProfile';
import IncorporationRequests from '@/components/professionalaccess/PAIncorporation';
import ReqRequests from '@/components/professionalaccess/PAReq';

export default {
  components: { ProfessionalAccessProfile, IncorporationRequests, ReqRequests },
  mixins: [auth],
  data () {
    return {
      activeView: 'profile',
      views: {
        PROFILE: {
          key: 'profile',
          route: 'professionalaccess'
        },
        INCORPORATION: {
          key: 'incorporation',
          route: 'professionalaccess-incorporation'
        },
        REQ: {
          key: 'req',
          route: 'professionalaccess-req'
        }
      }
    };
  },
  mounted () {
    this.setActiveView();
  },
  methods: {
    goTo (view) {
      this.activeView = view.key;
      this.$router.push({ name: view.route });
    },
    setActiveView () {
      Object.keys(this.views).forEach((viewKey) => {
        if (this.views[viewKey].route === this.$route.name) {
          this.activeView = this.views[viewKey].key;
        }
      });
    }
  },
  watch: {
    '$route.name' () {
      this.setActiveView();
    }
  }
};
</script>