<template>
  <div>
    <div v-if="isLoggedIn">
      <professional-access-home></professional-access-home>
    </div>
    <div
      v-if="isLoggedOut"
      class="professional-access"
    >
      <section class="section">
        <div class="container">
          <div class="columns is-multiline is-variable is-6">
            <div class="column is-7-desktop is-full-touch">
              <div class="content">
                <h1>{{ $t('professionalaccess.contentTitle') }}</h1>
                <div class="has-text-justified">
                  <p>{{ $t('professionalaccess.summary.0') }}</p>
                  <p>{{ $t('professionalaccess.summary.1') }}</p>
                  <p>
                    <span>{{ $t('form.content.needHelp') }}&nbsp;</span>
                    <router-link
                      :to="{ name: 'contact' }"
                      class="has-text-weight-bold"
                    >
                      {{ $t('form.content.contactUs') }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div class="has-text-center">
                <b-button
                  v-if="!$auth.isAuthenticated"
                  type="is-primary"
                  size="is-medium"
                  @click="login"
                >
                  {{ $t('login') }}
                </b-button>
              </div>
            </div>
            <div class="column is-5-desktop is-offset-0-desktop is-hidden-touch">
              <figure class="image is-4by3">
                <img
                  src="/professionals.svg"
                  alt=""
                >
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section class="section has-background-white-ter pb-6">
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-full">
              <h2>{{ $t('professionalaccess.summary.3') }}</h2>
            </div>
            <div class="column is-half pb-1-mobile">
              <div class="content">
                <ul class="fa-ul mt-0">
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.annualresolution') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.ordinarydividend') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.dividenddetermined') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.capitaldividend') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.shareissuing') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.sharebuyback') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.dissolutions') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.electionofdirector') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.resignationofadministrator') }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="column is-half pt-0-mobile">
              <div class="content">
                <ul class="fa-ul mt-0">
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.changeofheadquartersaddress') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.changeofname') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.addingothernames') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.annualdeclarationtoreq') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.currentupdatestatementtothereq') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.annualdeclarationtocorporationscanada') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.declarationofamendmenttocorporationscanada') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.incorporations') }}
                  </li>
                  <li><span class="fa-li"><b-icon
                        pack="far"
                        icon="check-circle"
                      ></b-icon></span>
                    {{ $t('professionalaccess.services.registerofindividualswithsignificantcontol') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import auth from '@/mixins/auth';
import ProfessionalAccessHome from '@/components/professionalaccess/PAHome';

export default {
  components: { ProfessionalAccessHome },
  metaInfo () {
    return {
      title: this.$t("professionalaccess.title"),
      meta: [
        { name: "description", content: this.$t("professionalaccess.description") }
      ]
    };
  },
  mixins: [auth]
};
</script>
