<template>
  <div>
    <h1 class="title">
      {{ $t('professionalaccess.profile.title') }}
    </h1>
    <section>
      <b-field :label="$t('professionalaccess.profile.name')">
        <b-input
          :value="user.name"
          disabled
        ></b-input>
      </b-field>
      <b-field :label="$t('professionalaccess.profile.email')">
        <b-input
          type="email"
          :value="user.email"
          disabled
        >
        </b-input>
      </b-field>
      <div class="content">
        <h3 class="mt-6">{{ $t('professionalaccess.profile.functionalitiesAvailable') }}</h3>
        <ul
          v-for="func in functionalitiesAvailable"
          :key="func"
        >
          <li>{{ $t('professionalaccess.profile.functionality.' + func) }}</li>
        </ul>
        <div v-if="functionalitiesNotAvailable.length">
          <h3 class="mt-6">{{ $t('professionalaccess.profile.functionalitiesNotAvailable') }}</h3>
          <ul
            v-for="func in functionalitiesNotAvailable"
            :key="func"
          >
            <li>{{ $t('professionalaccess.profile.functionality.' + func) }}</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import auth from '@/mixins/auth';

export default {
  mixins: [auth]
};
</script>